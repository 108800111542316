
/* =============================================================================
 Mall
 ==============================================================================*/

.height-md {
  height: 400px;
}
.mb-30 {
  margin-bottom: 30px;
}
#product_img {

}
#product_img .detail-img img {
  width: 100%;
  height: auto;
  border: 1px solid #eee;
    margin-bottom: 10px;
}
#nail img {
  border: 1px solid #eee;
  margin-bottom: 10px;
}

#product_info .product_tle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
}
.table-mall-detail>tbody>tr>td:first-child {
  width: 130px;
  font-weight: 400;
}
.table-mall-detail td {
  padding: 8px 0;
}
/* nav - Solid */
.nav-solid li {
  padding: 0 20px;
  background: transparent !important;
}

.nav-solid li .nav-link {
  padding: 5px;
  border-bottom: 2px solid transparent;
}

.nav-solid li .nav-link:hover {
  background: transparent
}

.nav-solid li .nav-link:hover {
  border-bottom: 2px solid #232323;
  color: inherit;
}

.nav-solid li .nav-link.active,
.nav-solid li .nav-link span.active {
  border-bottom: 2px solid #32b67a;
  font-weight: 600
}
#secondmenu {
  margin-bottom: 100px;
  }

  #secondmenu .shop-list-navbar>li {
  display: inline;
  padding-top: 0 !important ;
}
#secondmenu  .shop-list-navbar li a {
  padding: 10px 20px;
  border:1px solid #ddd;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
}
#secondmenu #leftoff a {color:#555555 !important;}
#secondmenu #lefton a {color:#32b67a !important;  border:1px solid #32b67a;}

@media (max-width:574px) {
  .nav-solid li {
    width: 33.33333%;
    padding: 0;
    text-align: center;
  }

  #secondmenu  .shop-list-navbar li a {
    padding: 8px 10px;
    font-size: 14px;
      margin-bottom: 10px;
  }
  #nail {
    margin-bottom: 15px;
  }

  #product_info .product_tle {
    margin-bottom: 15px;
  }

  #product_info {
    font-size: 14px;
  }

  #product_info .table-mall-detail td {
    padding: 5px 0;
  }
}



.table.table-order-detail2 #nail img {
  max-width: 55px;
  margin-bottom: 0;
}
.table.table-order-detail2 #nail_txt {
  padding-top: 0 !important;
  padding-left: 10px;
}
