/* ==============================
    Filter
================================= */
/* ---- Pill ---- */
.filter-pill li {
  margin: 0 20px !important;
  display: inline-block;
  float: inherit;
}

.filter-pill li:last-child {
  margin-right: 0;
}

.filter-pill li:first-child {
  margin-left: 0;
}

.filter-pill li a {
  border: none !important;
  padding: 0;
  border-radius: 0;
  padding: 5px 15px;
}

.filter-pill li a:hover,
.filter-pill li:active,
.filter-pill li.active a {
  border: none;
  background-color: #fdd947 !important;
  color: #000 !important;
}

.nav-tabs li a {
  margin: 0;
  line-height: 17px;
}

/* ----- Solid ---- */
ul.filter-solid {
  border-bottom: none;
}

.filter-solid li {
  margin: 0 20px !important;
  display: inline-block;
  float: inherit;
}

.filter-solid li:last-child {
  margin-right: 0 !important;
}

.filter-solid li:first-child {
  margin-left: 0 !important;
}

.filter-solid li a {
  border: none !important;
  padding: 0;
  border-radius: 0;
  padding: 5px 0;
  color: #9b9b9b;
}

.filter-solid li a:hover,
.filter-solid li.active a {
  border-bottom: 4px solid #d8b75f !important;
  background-color: transparent !important;
  color: #000 !important;
}

/* ---- Pill inverse ----- */
ul.filter-pill-inverse {
  border-bottom: none;
}

.filter-pill-inverse li {
  margin: 0 15px !important;
  display: inline-block;
  float: inherit;
}

.filter-pill-inverse li:last-child {
  margin-right: 0 !important;
}

.filter-pill-inverse li:first-child {
  margin-left: 0 !important;
}

.filter-pill-inverse li a {
  border: none !important;
  border-radius: 0;
  padding: 5px 20px;
  color: #9b9b9b;
}

.filter-pill-inverse li a:hover,
.filter-pill-inverse li:active,
.filter-pill-inverse li.active a {
  border-bottom: none;
  background-color: #d66e4a !important;
  color: #fff !important;
}

/* ---- Outline ---- */
.filter-outline li {
  margin-right: 16px;
  /* margin-bottom: 16px; */
  display: inline-block;
  float: inherit;
}

.filter-outline li:last-child {
  margin-right: 0;
}

.filter-outline li a {
  border: 1px solid #333;
  padding: 0;
  border-radius: 0;
  padding: 5px 20px 7px;
  display: inline-block;
  margin-bottom: 0;
}

.filter-outline li a:hover,
.filter-outline li:active,
.filter-outline li.active a {
  background-color: #333;
  color: #fff;
}

@media (max-width: 767px) {
  .filter-pill li,
  .filter-solid li,
  .filter-pill-inverse li,
  .filter-outline li {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

/* ==============================
    Grid
================================= */
/* masonry Basic */
.grid li {
  transition-duration: 0s;
  -moz-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
}

.grid img {
  width: 100%;
}

.grid-gallery {
  clear: both;
  float: left;
  width: 100%;
}

.gallery-img a {
  position: relative;
  z-index: 2;
  display: block;
}

.grid-gallery ul {
  list-style: none;
  padding: 0;
}

.grid-gallery figure {
  margin: 0;
  position: relative;
}

.grid-gallery figure img {
  display: block;
  width: 100%;
  opacity: 1;
  cursor: pointer;
}

.grid-gallery figcaption h4 {
  margin: 0 0 8px;
  padding: 0;
}

.grid-gallery figcaption i {
  font-size: 32px;
  color: #aeaeae;
  margin-bottom: 20px;
}

.grid {
  margin: 0 auto;
}

.grid li {
  box-sizing: border-box;
  float: left;
  margin: 0;
  display: inline-table;
}

.gallery-img {
  opacity: 1;
  transition: all 0.3s ease 0s;
  overflow: hidden;
}

.grid figure:hover img {
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  opacity: 0.15;
  /*cursor:url("../images/icon-zoom.png"), pointer;*/
}

.grid figure:hover figcaption {
  opacity: 1;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

/* grid - card */
.grid-card .grid figure {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.grid-card .grid figcaption {
  background-color: #fff;
  padding: 33px;
}

.grid-card .grid figcaption h4 {
  position: relative;
  padding-bottom: 40px;
}

.grid-card .grid figcaption h4:after {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 5% auto;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.125);
  display: block;
  content: '';
}

.grid-card .grid figcaption p {
}

.grid-card .grid figcaption i {
  font-size: 16px;
  color: inherit;
  margin-bottom: 0px;
  padding-right: 10px;
}

.grid-card .grid figure:hover img {
  transform: scale(1.1, 1.1);
  opacity: 0.7;
  cursor: url('https://smartpay.wisecare.co.kr/index/img/icon-zoom.png'),
    pointer;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* grid - style 1 Outline */
.grid-style1 .grid figure {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.grid-style1 .grid figcaption {
  opacity: 0;
  text-align: center;
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  left: 30px;
  top: 30px;
  position: absolute;
  background: #fff;
}

.grid-style1 .grid figcaption h4 {
  line-height: 25px;
  margin: 0;
  width: 100%;
  padding: 0 15px;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
}

.grid-style1 .grid figcaption h4 span {
  margin-top: 5px;
}

.grid-style1-border {
  border: 8px solid #fdd947;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  left: 20px;
  top: 20px;
  position: absolute;
}

/* grid - style 2 Bottom Slide */
.grid-style2 .grid figure {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  background: #999999;
}

.grid-style2 .grid figure img {
  position: relative;
  top: 0;
}

.grid-style2 .grid figure:hover img {
  top: -100px;
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 0.5;
}

.grid-style2 .grid figcaption {
  opacity: 0;
  text-align: center;
  width: 100%;
  height: 100px;
  bottom: -100px;
  position: absolute;
  padding: 25px 0;
  background: #fff;
}

.grid-style2 .grid figure:hover figcaption {
  bottom: 0;
  opacity: 1;
}

.grid-style2 .grid figcaption h4 {
  margin: 0;
  position: relative;
}

/* grid - style 3 */
.grid-style3 .grid figure img {
  position: relative;
  top: 0;
}

.grid-style3 .grid figure:hover img {
  top: 0;
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  opacity: 0.5;
}

.grid-style3 .grid figcaption {
  text-align: center;
  width: 100%;
  height: 100px;
}

.grid-style3 .explore-now {
  position: absolute;
  bottom: -36px;
  width: 100%;
  opacity: 0;
}

.grid-style3 .grid figure:hover .explore-now {
  opacity: 1;
  bottom: -37px;
}

.grid-style3 .grid li:hover figcaption {
  bottom: 37px !important;
}

.grid-style3 .explore-now a {
  padding: 7px 0;
  display: block;
  width: 100%;
  color: #fff;
}

/* grid - style 4 top Bar */
.grid-style4 .grid figure img {
  position: relative;
  top: 0;
  opacity: 1;
}

.grid-style4 .grid figure:hover img {
  top: 0;
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  opacity: 0.1;
  /*cursor:url("https://smartpay.wisecare.co.kr/index/images/icon-zoom-white2.png"), pointer;*/
  box-shadow: none;
  outline: none;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.grid-style4 .grid h4 {
  font-size: 22px;
  position: absolute;
  left: 45px;
  bottom: 0;
  width: 70%;
  z-index: 5;
  line-height: 25px;
  opacity: 0;
}

.grid-style4 .grid h4:after {
  border-bottom: 4px solid rgba(0, 0, 0, 1);
  content: '';
  left: 0;
  position: absolute;
  top: -25px;
  width: 20%;
}

.grid-style4 .grid figure:hover h4 {
  opacity: 1;
  bottom: 38px;
  letter-spacing: 1px;
}

.grid-style4 .grid h4 a {
  color: rgba(0, 0, 0, 0.9);
}

/* grid - style 5 */
.grid-style5 .grid figure {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  background: #000;
}

.grid-style5 .grid figcaption {
  opacity: 0;
  text-align: center;
  bottom: 60px;
  left: 0;
  right: 0;
  position: absolute;
}

.grid-style5 .grid figure:hover img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  cursor: pointer;
  opacity: 0.25;
}

.grid-style5 .grid figcaption h4 {
  font-size: 14px;
  line-height: 25px;
  margin: 0;
  width: 100%;
  padding: 30px 15px 10px 15px;
  text-align: center;
  position: relative;
  padding-top: 30px;
  opacity: 1;
  z-index: 2;
}

.grid-style5 .grid figcaption h4 a {
  color: #fff;
}

.grid-style5 .grid figcaption span {
  text-align: center;
  top: 47%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
}

/* grid - style 6 bottom Bar */
.grid-style6 .grid figure img {
  position: relative;
  top: 0;
  opacity: 1;
}

.grid-style6 .grid figure:hover img {
  top: 0;
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  opacity: 0.3;
  cursor: url('https://smartpay.wisecare.co.kr/index/images/icon-zoom-white2.png'),
    pointer;
  box-shadow: none;
  outline: none;
}

.grid-style6 .grid h4 {
  font-size: 18px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  /* bottom: 0; */
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  line-height: 25px;
  opacity: 0;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 1);
}

.grid-style6 .grid h4:after {
  content: '';
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  margin: 0 auto;
}

.grid-style6 .grid figure:hover h4 {
  opacity: 1;

  /* letter-spacing: 10px; */
}

.grid-style6 .grid h4 a {
  color: rgba(255, 255, 255, 1);
}

.grid-style2 .grid-style1-border,
.grid-style3 .grid-style1-border,
.grid-style4 .grid-style1-border,
.grid-style5 .grid-style1-border,
.grid-style6 .grid-style1-border {
  display: none;
}

/* ---- Column ----- */
.column-5 .grid li {
  width: 20%;
}

.column-4 .grid li {
  width: 33.33%;
}

.column-3 .grid li {
  float: left;
  width: 33.33%;
}

.column-2 .grid li {
  float: left;
  width: 50%;
}

/* ---- gutter ---- */
.gutter .grid-gallery ul {
  margin-left: -22px;
}

.gutter .grid li {
  padding: 0 0 22px 22px;
}

.gutter-sm .grid-gallery ul {
  margin-left: -15px;
}

.gutter-sm .grid li {
  padding: 0 0 15px 15px;
}

.gutter-lg .grid-gallery ul {
  margin-left: -40px;
}

.gutter-lg .grid li {
  padding: 0 0 40px 40px;
}

/* portfolio grid - with title */
.work-with-title .grid figcaption {
  opacity: 1;
  position: relative;
  bottom: 0;
  padding: 29px 0 30px 0;
  background: rgba(255, 255, 255, 1);
  z-index: 2;
}

.work-with-title .grid li figcaption h4,
.work-with-title .grid figcaption h4 a,
.work-with-title .grid li figcaption p {
  color: #888888;
}

.work-with-title .grid figcaption h4 a {
  display: block;
}

.work-with-title .grid li:hover figcaption h4,
.work-with-title .grid li:hover figcaption h4 a {
  color: #000;
}

.work-with-title .grid li:hover figcaption {
  bottom: 50px;
}

/* portfolio grid - with title - transparent figcaption */
.work-with-title.transparent-figcaption .grid figcaption {
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  position: absolute;
}

.work-with-title.transparent-figcaption .grid figure:hover figcaption {
  background: rgba(0, 0, 0, 0.75);
}

.work-with-title.transparent-figcaption .grid li figcaption h4 {
  color: #fff;
  font-size: 13px !important;
}

/* onepage portfolio details popup */
.popup-info {
  display: none;
}

.half-project-bg {
  height: 550px;
}

.slider-typographi-text .slider-subtitle {
  line-height: 64px !important;
}

.spend-year,
.spend-time {
  border-right: 1px solid #e5e5e5;
  float: left;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 47px;
  min-height: 95px;
  text-align: center;
  width: 50%;
}

.spend-year span,
.spend-time span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 0;
  margin-top: 35px;
  text-transform: uppercase;
}

.project-img-gallery {
  padding: 15px 0;
}

@media (max-width: 1500px) {
  .grid-style1 .grid figcaption h4 {
    padding: 0 50px;
  }
}

@media (max-width: 1050px) {
  .grid-style2 .grid figcaption {
    padding: 17px 20px;
  }
}

@media (max-width: 991px) {
  .grid-style1 .grid figcaption h4 {
    font-size: 18px;
    font-weight: 400;
  }

  .grid-style2 .grid figcaption {
    padding: 30px 15px;
  }

  .gutter-lg .grid-gallery ul {
    margin-left: -20px;
  }

  .gutter-lg .grid li {
    padding: 0 0 20px 20px;
  }

  .grid-style4 .grid figure:hover h3 {
    bottom: 23px;
  }

  .grid-style4 .grid h4 {
    left: 25px;
  }

  .column-3 .grid li {
    width: 50%;
  }

  .column-4 .grid li {
    width: 50%;
  }

  .column-5 .grid li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .grid-style1 .grid figcaption {
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    left: 10px;
    top: 10px;
  }

  .half-project-bg {
    height: 350px;
  }

  .ajax-popup-title-text {
    background: transparent;
    padding: 0;
  }
}

@media (max-width: 574px) {
  .column-3 .grid li {
    width: 100%;
  }
}

.modal-content > .close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  width: 80px;
  height: 80px;
  z-index: 10;
}

.modal.fade .item {
  opacity: 1 !important;
}

/* ---- Large ---- */
.modal.modal-large .modal-dialog {
  width: calc(100% - 100px);
  max-width: 1400px;
  min-height: 100vh;
  margin: 50px auto;
}

.modal.modal-large .modal-body {
  padding: 0;
}

.modal.modal-large .modal-content {
  border-radius: 0;
  border: none;
}

/* ---- Content ---- */
.modal-body section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.modal-body h4 + p {
  margin-top: 30px;
}

.modal-body .img-wrap + .img-wrap {
  margin-top: 30px;
}

@media (max-width: 991px) {
  .modal.modal-large .modal-dialog {
    width: calc(100% - 30px);
    margin: 15px auto;
  }

  .modal-content > .close {
    width: 40px;
    height: 40px;
  }

  .modal-body section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .modal-body .height-60 {
    min-height: 400px;
  }

  .modal-body .img-wrap + .img-wrap {
    margin-top: 0px;
  }
}

/* detail page */
.data_table_list.product-detail > li > b {
  width: 120px;
  padding-left: 10px;
}

.product-detail .row {
  padding-bottom: 50px;
  padding-top: 50px;
}

.list-detail {
  border-top: 2px solid #222;
}

.list-detail li {
  width: 33.33333%;
  text-align: center;
  margin-top: 30px;
}

.list-detail li:not(:first-child) {
  border-left: 1px solid #eee;
}
