label {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.form-style:not([type="image"]) {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  font-size: 16px;
  line-height: 1.25;
  color: #222;
  background-color: #f6f6f6;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  box-shadow: none;
}

textarea.form-style {
  height: 160px;
  overflow-x: hidden;
  font-size: 14px !important;
  color: rgba(0, 0, 0, .6)
}

input.form-style:not([type="radio"]):not([id="id_all_select"]):not([type="checkbox"]):not([type="image"]),
select.form-style {
  height: 40px;
}

.form-style::-ms-expand {
  background-color: transparent;
  border: 0;
}

input.form-style:focus,
select.form-style:focus {
  border: 1px solid #222;
  box-shadow: none;
  outline: 0 none;
  background-color: #fff;
}

.form-style::-webkit-input-placeholder,
.form-style::-moz-placeholder,
.form-style:-ms-input-placeholder,
.form-style::placeholder {
  color: #636c72;
  opacity: .4;
}

textarea.member_border {
  font-size: 14px;
  color: #777;
}
/* ---- With Icon ----- */
.input-with-icon {
  position: relative;
}

.input-with-icon i {
  position: absolute;
  font-size: 1.4375em;
  margin-left: 20px;
  top: 13px;
  left: 0;
color: #b3b3b3;
}

.input-with-icon label+i {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.input-with-icon input {
  padding-left: 60px !important;
  width: 100%;
  height: 55px !important;
}

.input-with-icon label+i+input {
  margin-top: 0;
}

.input-with-icon {
  margin-bottom: 10px;
}

.input-with-icon+div>textarea {
  padding-left: 1.625em;
}

.input-with-icon.label-none>label {
  display: none;
}

@media all and (max-width: 767px) {
  .input-with-icon {
    margin-bottom: 5px;
  }
}


.list-login li {
	padding-left:10px;
	padding-right:10px;
	font-size:16px;
}
.list-login li:not(:last-child) {
	border-right:1px solid #ddd;
}

.box-member {
  border: 1px solid #eee;
  background-color: #fff;
}

/*******************
Table
********************/

.table-member .member_td_bgcolor {
  background-color: #f6f6f6;
  vertical-align: middle;
  font-size: 14px;
}
.table-member .member_txt tr,
.table-member .member_txt td {
  border: none;
}
/*******************
Form style
********************/
input[name="addr"],
input[name="addr2"] {
  margin-top: 5px;
}

  @media (max-width: 991px) {
    input.member_border {
      width: 100%;
    }
    select.member_border {
      width: 100%;
      max-width: 200px;
    }
    input[name="phone_no1"],
    input[name="phone_no2"],
    input[name="phone_no3"],
    select[name="mobile_no1"],
    input[name="mobile_no2"],
    input[name="mobile_no3"] {
      width: 30%;
    }
    select[name="email2"],
      input[name="email1"] {
      margin-bottom: 5px;
    }
    input[name="email1"],
    input[name="email3"] {
      width: 50%;
        }

  }

  @media (max-width: 574px) {
    .table-member tr {
      border-top: 1px solid #dee2e6;
    }
    .table-member td {
      display: inline-block;
      border: none;
    }
    .table-member .member_td_bgcolor {
      width: 100%;
      text-align: left;
      background-color: transparent;
      padding-bottom: 0;
      font-weight: 500;
    }
      input[name="email1"],
      input[name="email3"] {
        width: 90%;
          }

  }


  /* mypage */
  .mypage-title {
    margin-bottom: 40px;
  }
.table-order .table_cont4 a {
  text-decoration: underline;
  color: #32b67a;
}
.table-order>tbody>tr>th:nth-child(4) {
  width: 130px !important;
}
.table-order>tbody>tr>th:nth-child(5) {
  width: 130px !important;
}
.mypage-title-sub {
  font-weight: 600;
}
.mypage-title-sub span {
    color: #32b67a;
    font-weight: 800;
    margin-right: 10px;
}
.table-order-detail th {
  font-weight: 400;
}
.table-order-detail .table_tle1 {
background-color: #f6f6f6;
}


.order-price {

}
.order-price li {
  padding: 8px 0;
}
  .table-order-detail2 th {
    font-weight: 400;
    background-color: #f6f6f6;
  }

  .table-exit {
    border-left : none;
    border-right: none;
    border-top: 1px solid #dee2e6;
  }
@media (max-width:574px) {
    .table-order-detail,
    .table-order-detail2,
    .order-price {
      font-size: 14px;

    }
  .table-order-detail th,
  .table-order-detail td {
    width: 100%;
    display: inline-block;
  }
    .table-order-detail td {
border-top: none;
    }
}




/* Tab Menu */


 .f45-tab {
  display: table;
  width: 100%;
}

 .f45-tab>a  {
  /* display: table-cell; */
  display: inline-block;
  /* border: 1px solid rgba(120, 130, 140, 0.4); */
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #222;

  text-align: center;
  padding: 15px 10px;
  background: #fff;
  color: rgba(38, 50, 56, 0.7);
}
 .f45-tab>a:not(:last-child) {
   border-right: 1px solid #ddd;
 }
 .f45-tab>a.active {
  border: 1px solid #222;
  border-bottom-color: transparent;
  background: #ffffff;
  font-weight: 400;
    color: rgba(38, 50, 56, 1);
}
 .f45-tab.w-20>a {
   width: 20%;
 }
 .f45-tab.w-25>a {
   width: 25%;
 }
 .f45-tab.w-33>a {
   width: 33.33333%;
 }
 .f45-tab.w-50>a {
   width: 50%;
 }
 @media (max-width:574px) {
    .f45-tab>a {
      font-size: 13px;
      padding: 14px 5px;
    }
 }
