@import url('common.css');
@import url('navbar.css');
@import url('member.css');
@import url('board.css');
@import url('work.css');
@import url('shop.css');
@import url('main.css');
@import url('033.css');
/*-- ==============================================================
 Font
 ============================================================== */
@import url('https://fonts.googleapis.com/css?family=Noto%20Sans%20KR:100,300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Noto%20Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800');

body {
  font-family:
    'Noto Sans KR',
    'Noto Sans',
    'Roboto',
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
  line-height: 1.682;
  font-size: 18px;
}

body .font-eng {
  font-family: 'Montserrat', sans-serif;
}

body .font-sans {
  font-family: 'Roboto', sans-serif;
}

.dropdown-item,
.navbar-nav .nav-link {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

p {
  font-weight: 300;
}

.btn {
  border-radius: 0;
}

/*-- ==============================================================
 Layout
 ============================================================== */
.navbar > .container,
#owl-intro .container,
section > .container {
  max-width: 1500px;
  width: 100%;
}

.product-detail > .container {
  max-width: 1140px;
}

/* Service */
.height-service {
  height: 700px;
}

@media (max-width: 991px) {
  .height-service {
    height: 400px;
  }
}

/* Work */
.height-lg {
  height: 700px;
}

.height-md {
  height: 500px;
}

.height-sm {
  height: 300px;
}

@media (max-width: 991px) {
  .height-lg,
  .height-md,
  .height-sm {
    height: 180px;
  }
}

/* Contact */
.height-400 {
  height: 400px;
}

.height-800 {
  height: 800px;
}

@media (max-width: 991px) {
  .height-400 {
    height: 400px;
  }

  .height-800 {
    height: 500px;
  }
}

@media (max-width: 574px) {
  .height-400 {
    height: 300px;
  }

  .height-800 {
    height: 420px;
  }
}

/*-- ==============================================================
 Sub Title
 ============================================================== */
.height-title {
  height: 500px;
}

.sub-title {
  color: #fff;
}

@media (max-width: 991px) {
  .height-title {
    height: 300px;
    margin-top: 55px;
  }

  .height-title .content-center {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  .sub-title {
    margin-top: 0;
  }
}

/*==============================
contact
==-=============================  */
.bg-dark.text-white h2 {
  color: #fff;
}

.bg-dark.text-white p {
  color: rgba(255, 255, 255, 0.6);
}

/*******************
/* Sub Tab navbar
*******************/
.sub-navbar-wrap {
  margin-top: -58px;
}

.sub-navbar-wrap .sub-navbar li {
  /* border-top: 1px solid #ccc; */
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  height: 58px;
  background-color: rgba(0, 0, 0, 0.15);
}

.sub-navbar-wrap .sub-navbar li:first-child {
  border-left: none;
}

.sub-navbar-wrap .sub-navbar li:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.sub-navbar-wrap .sub-navbar li.active {
  background-color: #fff;
}

.sub-navbar-wrap .sub-navbar li a {
  display: blcok;
  color: rgba(255, 255, 255, 0.8);
  line-height: 58px;
  padding: 0;
}

.sub-navbar-wrap .sub-navbar li.active a {
  color: #1b1d1f;
  font-weight: 600;
}

@media (max-width: 574px) {
  .sub-navbar-wrap .sub-navbar li a {
    font-size: 13px;
  }
}

/*  */
.service-01 li:not(:last-child) {
  margin-bottom: 25px;
}

.service-01 h5 {
  font-weight: 600;
  margin-bottom: 5px;
}

.list-icon {
  /* padding-left: 40px; */
}

.list-icon li {
  position: relative;
  padding-bottom: 20px;
  line-height: 1.45;
  padding-left: 36px;
}

.list-icon i {
  position: absolute;
  top: 5px;
  left: 0;
  margin-right: 10px;
}

.faq .card-header {
  border-bottom: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.04);
}

#owl-gallery .hero-wrap {
  height: 400px;
}

@media (max-width: 991px) {
  .half-bg.half-about {
    background-color: #32b67a;
  }

  .text-md-white {
    color: #fff;
  }
}

.navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}
