.navbar:before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  transition: opacity 0.15s linear;
  opacity: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.navbar:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.navbar-brand {
  font-weight: 500;
  line-height: 1;
  color: inherit !important;
}
.navbar-brand img {
  height: 36px;
}

@media (max-width: 1300px) {
  .navbar:before {
    opacity: 1;
  }
}

.navbar-dark > .container {
  position: relative;
}

.navbar-light:before {
  opacity: 1;
}

.navbar-nav .nav-link {
  font-size: 13px;
  font-weight: 700;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.2s ease-in-out;
  transition-property: color;
  letter-spacing: 0;
  text-transform: uppercase;
}

@media (min-width: 1400px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (min-width: 1400px) {
    .navbar-nav .nav-link:before {
      position: absolute;
      right: 1rem;
      bottom: -1.25rem;
      left: 1rem;
      display: none;
      content: '';
      border-top: 3px solid #32b67a;
    }

    .navbar-nav .nav-link:focus,
    .navbar-nav .nav-link:hover {
      outline: none;
    }
  }

  @media (min-width: 1400px) {
    .navbar-nav .nav-item.active > .nav-link:before,
    .navbar-nav .nav-link.active:before {
      display: block;
    }
  }

  @media (max-width: 1199.98px) {
    .navbar-nav .dropdown-menu {
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
    }
  }

  @media (min-width: 1200px) {
    .navbar-nav .dropdown-menu {
      transform: translateY(0.5rem);
      /* opacity: 0 */
    }

    .navbar-nav .dropdown-menu.show {
      animation-name: e;
    }
  }

  .navbar-nav .nav-item-divider {
    display: none;
  }

  @media (min-width: 1200px) {
    .navbar-nav .nav-item-divider {
      display: block;
    }
  }

  .navbar-nav .nav-item-divider .nav-link {
    opacity: 0.25;
  }

  .navbar-nav .nav-item-divider .nav-link > span {
    border-left: 1px solid;
  }

  .dropdown-menu {
    font-size: 0.9375rem;
    position: absolute;
    z-index: 1000;
    top: 100%;
    /* top: 80%; */
    left: 0;
    display: none;
    float: left;
    min-width: 12.5rem;
    margin: 0.125rem 0 0;
    padding: 0;
    list-style: none;
    text-align: left;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0;
  }

  .dropdown-menu[x-placement^='bottom'],
  .dropdown-menu[x-placement^='left'],
  .dropdown-menu[x-placement^='right'],
  .dropdown-menu[x-placement^='top'] {
    right: auto;
    bottom: auto;
  }

  .dropdown-divider {
    overflow: hidden;
    height: 0;
    margin: 0.5rem 0;
    border-top: 1px solid #e9ecef;
  }

  .dropdown-item {
    font-weight: 400;
    display: block;
    clear: both;
    width: 100%;
    padding: 0.75rem 1.5rem;
    text-align: inherit;
    white-space: nowrap;
    color: #212529;
    border: 0;
    background-color: transparent;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    color: #212529;
    background-color: #f8f9fa;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    text-decoration: none;
    color: #32b67a;
    background-color: transparent;
  }

  .dropdown-item.disabled,
  .dropdown-item:disabled {
    pointer-events: none;
    color: #6c757d;
    background-color: transparent;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-header {
    font-size: 0.8125rem;
    display: block;
    margin-bottom: 0;
    padding: 0 1.5rem;
    white-space: nowrap;
    color: #212529;
  }

  .dropdown-item-text {
    display: block;
    padding: 0.75rem 1.5rem;
    color: #212529;
  }

  .dropdown-menu {
    /* opacity: 0 */
  }

  .dropdown-menu.show {
    animation: 0.2s forwards d;
  }

  @keyframes d {
    to {
      opacity: 1;
    }
  }

  @keyframes e {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (min-width: 1200px) {
    .dropdown-menu > li + li {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 1199.98px) {
    .dropdown-menu .dropdown-menu .dropdown-item {
      padding-left: 2.25rem;
    }
  }

  .dropdown-item {
    font-size: 13px;
    font-weight: 500;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: background-color 0.1s;
    letter-spacing: 0;
  }

  @media (min-width: 1200px) {
    .dropdown-item {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }

  @media (min-width: 1200px) {
    .dropdown-item + .dropdown-item {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .dropdown-item.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /*

.dropdown:hover .dropdown-menu{
  display:block;
}
@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu{
     display:block;
  }
} */
}
