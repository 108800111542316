body {
  color: #3f4448;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #3f4448;
}
a,
a.link {
  color: #3f4448;
}
a:hover,
a.link:hover,
a.link:focus {
  color: #1694ff;
}
p,
.p {
  color: #777;
}
.bg-accent {
  background-color: #1694ff;
}
html body .bg-light,
html body .bg-light-gray {
  background-color: #f8f8f8 !important;
}
.bg-footer {
  background-color: #3f4448;
}
.bg-dark {
  background-color: #3f4448 !important;
}
.bg-gray {
  background-color: #f7f7f7;
}
hr.separator-break.border-accent {
  border-color: #1694ff;
}

.bg-footer.text-white > *,
.bg-footer.text-white h6,
.bg-footer.text-white p {
  color: #fff !important;
  font-size: 15px;
  font-weight: 300;
}
.text-accent {
  color: #1694ff;
}

/* ===========================================================================
    Button
 */
.btn-link {
  text-decoration: none;
}
.btn-link:hover {
  color: #1694ff;
}
.btn-accent,
.btn-accent.disabled {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  background-color: #1694ff;
  color: #fff;
}
.btn-accent:hover,
.btn-accent.disabled:hover {
  background-color: #1694ff;
}
.btn-accent.active,
.btn-accent:active,
.btn-accent:focus,
.btn-accent.disabled.active,
.btn-accent.disabled:active,
.btn-accent.disabled:focus {
  color: #ffffff !important;
  background: #3f4448;
  border-color: #3f4448;
}
.btn-outline-accent {
  color: #1694ff;
  background-color: transparent;
  border-color: #1694ff;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-accent:hover,
.btn-outline-accent:focus,
.btn-outline-accent.focus {
  background: #1694ff;
  color: #3f4448;
  border-color: #1694ff;
}
.btn-outline-accent.active,
.btn-outline-accent:active,
.btn-outline-accent:focus {
  background: #1694ff;
}

.btn-inverse,
.btn-inverse.disabled {
  background: #3f4448;
  border: 1px solid #3f4448;
  color: #ffffff;
}
.btn-inverse:hover,
.btn-inverse.disabled:hover {
  background: #2f3947;
  color: #1694ff;
  border: 1px solid #2f3947;
}
.btn-inverse.active,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus {
  background: #2f3947;
  color: #1694ff;
}
.btn-outline-inverse {
  color: #3f4448;
  background-color: transparent;
  border-color: #3f4448;
}
.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
  background: #3f4448;
  border-color: #3f4448;
  color: #ffffff;
}

.box-icon-contact .box-icon.box-icon-bg .box-icon-title > i {
  background-color: #3f4448;
  color: #fff;
}

span.headline {
  border-color: #1694ff;
}

[data-overlay].accent-bg:before {
  background: #1694ff;
}

/*******************
Sub Menu
********************/
.nav-submenu .nav-link {
  background-color: #f6f6f6;
}
.nav-submenu .active {
  color: #1694ff;
}

/* ===========================================================================
    Navigation
 */
.navbar:before {
  background-color: #1694ff;
  border-bottom: none;
}
.navbar-dark {
  color: #fff;
}
.navbar-light {
  color: #fff;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #1694ff;
}
.navbar-light .navbar-nav .nav-link {
  color: inherit;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #3f4448;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #3f4448;
}
.navbar-light .navbar-toggler {
  color: #212529;
  border-color: transparent;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: #212529;
}
.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover,
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: #ea554e;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #1694ff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #1694ff;
}
.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: transparent;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: #fff;
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #1694ff;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #1694ff;
}
.nav-solid li .nav-link.active,
.nav-solid li .nav-link span.active {
  border-bottom: 2px solid #ea554e;
}
/* ===========================================================================
    Overlay
 */
.dark-overlay::after {
  background: #000;
  opacity: 0.3;
}
.light-overlay::after {
  background: #fff;
  opacity: 0.3;
}
.sub-overlay::before {
  background: #3f4448;
  opacity: 0.7;
}
.sub-overlay .sub-title {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.main-contact .box-icon.box-icon-bg .box-icon-title > i,
.main-contact .box-icon.box-icon-bg > i {
  background-color: #1694ff;
  color: inherit;
}

.wrap-feature-24 .service-24 i {
  color: #1694ff;
}
