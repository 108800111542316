
.font-0 {
  font-size: 0;
  line-height: 0;
}
.font-0  img {
  margin: 0;
}
#post_area img {
  max-width: 100%;
  height: auto !important;
}

.board_bgcolor {
  vertical-align: middle !important;
    /* background-color: #f6f6f6; */
}
.table {
  border-bottom: 1px solid #dee2e6;
}
.tableA-list .bbschk {width: 40px;}
.tableA-list .bbsday {width: 15%;}
.tableA-list .bbsno {width: 60px;}
.tableA-list .bbswriter {width: 120px;}
.tableA-list .bbsetc_dateof_write {width: 120px;}
.tableA-list .bbsnewf5 {width: auto;}

.tableA-write th,
.tableA-write td {
  padding: 10px;
}
.tableA-write .innerTable tr,
.tableA-modify .innerTable tr,
.tableB-write .innerTable td,
.tableB-modify .innerTable td {
  border: none;
}
.tableA-write .board_bgcolor,
.tableA-lead .board_bgcolor,
.tableA-modify .board_bgcolor,
.tableB-write .board_bgcolor,
.tableB-lead .board_bgcolor,
.tableB-modify .board_bgcolor,
.tableC-write .board_bgcolor,
.tableC-lead .board_bgcolor,
.tableC-modify .board_bgcolor {
  background-color: #f6f6f6;
}
.tableA-write>tbody>tr td span,
.tableA-modify>tbody>tr td span{
  font-size: 14px !important;
}


.tableC-list>tbody>tr>td {
  padding: 0;
}
.tableC-list .webzine_type2_table_line {
  display: none;
}
.tableC-list .webzine_type2 {
  width: 100%;
}
.tableC-list .webzine_type_text_td_left {
  width: 75%;
  vertical-align: middle;
}
.tableC-list .webzine_type_img_td {
  width: 25%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tableC-list .webzine_type_img_td img {
  width: 100%;
  height: auto;
}

.tableC-list .webzine_writer {
display: none;
}

.tableC-list .webzine_dateof_write {
 font-size: 14px;
 color: #888;
 padding-top: 30px;
}

.tableC-list .webzine_subject .obj_name ,
.tableC-list .webzine_description .obj_name {
  display: none;
}
.tableC-list .webzine_subject .div_news_title {
  font-size: 28px;
  margin-bottom: 10px;
  display: block;

}
.tableC-list .webzine_type2 tr:first-child td {
  border-top: none;
}

.tableB-list.table th,
.tableB-list.table td {
  border-top: none;
}
.tableB-list>tbody>tr>td {
  width: 24.5%;
  display: inline-block;
}
.tableB-list table>tbody>tr>td {
  padding: 0;
}
.tableB-list table>tbody>tr>td img {
  width: 100%;
  height: auto;
}
.tableB-list table>tbody>tr .gallery_subject {
  padding: 10px;
}

.table-imgList > table tbody tr td img {
  width: 135px;
  height: auto !important;
}


@media (max-width:991px) {
  .tableB-list>tbody>tr>td {
    width: 50%;
    display: inline-block;
  }
}
@media (max-width:574px) {
  .tableA-list .bbsno {width: 50px;}
  .tableA-write tr td,
  .tableA-modify tr td,
  .tableC-write tr td,
  .tableC-modify tr td {
    display: inline-block;
  }
    .tableA-write tr,
    .tableA-modify tr,
    .tableC-write tr,
    .tableC-modify tr {
      border-top: 1px solid #dee2e6;
    }
    .tableA-write th,
    .tableA-write td,
    .tableA-modify th,
    .tableA-modify td,
    .tableC-write th,
    .tableC-write td,
    .tableC-modify th,
    .tableC-modify td {
      border-top: none;
    }

    .tableA-write>tbody>tr td span,
    .tableA-modify>tbody>tr td span,
    .tableC-write>tbody>tr td span,
    .tableC-modify>tbody>tr td span{
      font-size: 14px !important;
    }
    .tableA-write .board_bgcolor,
    .tableA-modify .board_bgcolor,
    .tableC-write .board_bgcolor,
    .tableC-modify .board_bgcolor {
      padding-bottom: 0 !important;
    }

    .tableC-list .webzine_type_text_td_left,
    .tableC-list .webzine_type_img_td {
  display: block;
  width: 100%;
    }

    .tableB-list>tbody>tr>td {
      width: 50%;
      display: inline-block;
    }
}


.paging {
  font-size: 11px;
  text-align: center;
  padding: 30px 0px;

}

.paging b {
  font-size: 11px;
  cursor: default;
  color: #fff;
  border-color: #222;
  background-color: #222;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  border: 1px solid #222;
}

.paging a:link {
    color: #23527c;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.paging a:visited {
  color: #555;
  font-weight: normal;
  text-decoration: none
}

.paging a:hover {
  color: #555555;
  font-weight: normal;
  text-decoration: underline
}


/*******************
input style
********************/
input#border,
textarea.formmail_textarea_style {
  width: 100%;
}
input#border[name="writer"],
input#border[name="password"] {
  width: 300px;
}

input#border,
input.formmail_border {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
}
input[type=file] {
  border: none;
  padding:0;
}

textarea#border,
textarea.formmail_textarea_style  {
  border: 1px solid #ccc
}


@media (max-width: 991px) {
  input.formmail_border:not([name="phone_no1"]):not([name="phone_no2"]):not([name="phone_no3"]),
  textarea.formmail_textarea_style {
    width: 100%;
  }
  input#border[name="writer"],
  input#border[name="password"] {
    width: 100%;
  }

}


  .table.table-formmail .formmail_title_bgcolor {
    width: 150px !important;
    display: inline-block;
  }
  .table.table-formmail .formmail_cell_bgcolor {
    display: inline-block;
  }


@media (max-width: 993px) {
  .table.table-formmail .formmail_title_bgcolor,
  .table.table-formmail .formmail_cell_bgcolor {
    display: block;
    width: 100% !important;
  }
  .table.table-formmail .formmail_title_bgcolor {
    text-align: left;
    /* background-color: #f6f6f6; */
    font-weight: 500;
    padding-bottom: 0;
  }
    .table.table-formmail .formmail_cell_bgcolor {
      border-top: none;
    }
    input.formmail_border[name="mobile_no1"],
    input.formmail_border[name="mobile_no2"],
    input.formmail_border[name="mobile_no3"] {
      width: 30% !important;
    }
}
